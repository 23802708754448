import { createSelector } from 'reselect'
import LocalStorage from 'sites/utils/LocalStorage';

const innerStateSelector = state => state.common

const isMenuOpenedSelector = (state) => {
   return state.common.menu.isMenuOpened;
};
const isMenuAnimationSelector = (state) => {
   return state.common.menu.animationClass;
};
const authUserSelector = createSelector(
   innerStateSelector,
   (state) => {
      const data = state.authUser
      if(data && 'number' === typeof state.walletBalance && state.walletBalance !== data.wallet_balance) {
         data.wallet_balance = state.walletBalance
      }
      return data
   }
);

const isSiteInitedSelector = (state) => {
   return state.common.isSiteInited;
};
const siteInfoSelector = (state) => {
   return state.common.siteInfo;
};
const appIdSelector = (state) => {
   return state.common.siteInfo ? state.common.siteInfo.app_id : null;
}
const siteNameSelector = (state) => {
   return state.common.siteInfo ? state.common.siteInfo.subdomain : null;
}
const suggestedMembershipPlanPriceSelector = (state) => {
   return state.common.siteInfo ? state.common.siteInfo.suggested_membership_plan_price : null;
}

const screenWidthSelector = (state) => {
   return state.common.screenWidth;
};

const walletBalanceSelector = createSelector(
   innerStateSelector,
   (state) => {
      let res = state.walletBalance
      if(!state.walletBalance && state.authUser) {
         res = state.authUser.wallet_balance
      }
      return res
   }
);

const isFetchingBalanceSelector = createSelector(
   innerStateSelector,
   state => state.isFetchingBalance
);
const customPagesSelector = createSelector(
   innerStateSelector,
   state => state.customPages
);

const onlineStreamSelector = (state) => {
   return !!state.livestream.initData && state.livestream.initData.is_live_stream_session_active && !state.livestream.initData.is_stream_offline;
};

const walletMediaDataSelector = createSelector(
   innerStateSelector,
   state => state.walletMediaData
);
const isDarkBgSelector = (state) => {
   return state.common.siteInfo ? !!state.common.siteInfo.is_dark_bg : false;
}

const isFakeUserSelector = createSelector(
   innerStateSelector,
   state => state.isFakeUser
);

const viewAsModalSelector = (state) => {
   return state.common.viewAsModal;
};
const isLouisianaBlockedSelector = (state) => {
   return state.common?.siteInfo?.is_louisiana_blocked;
};
const promoCodeSelector = (state) => {
   return state.purchase?.promoCode
}
const promotionNotificationSelector = (state) => {
   return state.common.promotionNotification
}
const ageVerificationStatusSelector = (state) => {
   return state.common.ageVerificationStatus
}
const isAdultVerifiedSelector = (state) => {
   return state.common.isAdultVerified
}

const isFetchingLogoutSelector = (state) => {
   return state.common.isFetchingLogout
}
const isOpenPenndingPaymentModalSelector = (state) => {
   return state.common.isOpenPenndingPaymentModal
}

const totalOrdersCountSelector = (state) => {
   return state.common.total_order_requests_count
}
const newOrdersCountSelector = (state) => {
   return state.common.new_order_requests_count
}

const isOfflineModeSelector = (state) => {
   return state.common.isOfflineMode
}

const isAgeVerificationRequiredSelector = (state) => {
   const isAdultVerifiedStatusToken = state.common.isAdultVerified
   return isAdultVerifiedStatusToken && isAdultVerifiedStatusToken !== 'verified' && isAdultVerifiedStatusToken !== 'unnecessary' && LocalStorage.getItem('IS_AGE_VERIFIED_BY_ID') !== 'true' && localStorage.getItem('IS_AGE_VERIFIED_BY_ID') !== 'true'
}
const showNSFWModalSelector = (state) => {
   return state.common.showNSFWModal
}

const isPageContentLoadedForAgeVerificationModalSelector = (state) => {
   return state.common.isPageContentLoadedForAgeVerificationModal
}

export {
   isMenuOpenedSelector,
   authUserSelector,
   isSiteInitedSelector,
   isMenuAnimationSelector,
   siteInfoSelector,
   appIdSelector,
   suggestedMembershipPlanPriceSelector,
   screenWidthSelector,
   siteNameSelector,
   walletBalanceSelector,
   isFetchingBalanceSelector,
   customPagesSelector,

   onlineStreamSelector,
   walletMediaDataSelector,
   isFakeUserSelector,
   isDarkBgSelector,
   viewAsModalSelector,
   isLouisianaBlockedSelector,
   promoCodeSelector,
   promotionNotificationSelector,
   ageVerificationStatusSelector,
   isAdultVerifiedSelector,
   isFetchingLogoutSelector,
   isOpenPenndingPaymentModalSelector,
   totalOrdersCountSelector,
   isOfflineModeSelector,
   newOrdersCountSelector,
   isAgeVerificationRequiredSelector,
   showNSFWModalSelector,
   isPageContentLoadedForAgeVerificationModalSelector,
}
