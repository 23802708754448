const initialState = {
   isSiteInited: false,
   authUser: null,
   menu: {
      isMenuOpened: false,
      animationClass: 'default',
   },
   siteInfo: {},
   screenWidth: typeof window === 'object' ? window.innerWidth : null,
   isFetchingBalance: false,
   customPages: [],
   isFakeUser: false,
   viewAsModal: {
      isOpen: false,
      type: null,
      id: null,
   },
   promotionNotification: false,
   ageVerificationStatus: null,
   isFetchingLogout: false,
   isOpenPenndingPaymentModal: false,
   total_order_requests_count: 0,
   isOfflineMode: false,
   showNSFWModal: false,
   isPageContentLoadedForAgeVerificationModal: false, // call action and set it true on pages where we need to wait until page loads and then show 18+ modal
};

export default initialState;
