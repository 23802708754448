import { initSiteDetails, initLiveStream, verifyAgeById, getRelatedContents } from 'sites/api/MixApi';
import { chatInit, getWalletBalance, updateTranslteLanguage } from 'sites/api/AuthApi';
import { updatePreviewMember } from 'sites/api/GuestApi';
import Auth from 'sites/utils/Auth';
// import socketIOClient from 'socket.io-client';
import QueryParams from 'sites/utils/QueryParams';

import {
   siteDetailsInitStart,
   siteDetailsInitCompleted,
   siteDetailsInitFailed,

   updateWalletBalanceStart,
   updateWalletBalanc,
   updateWalletBalanceFailed,

   setViewAsModal,
   updateAuthUser,
   ageVerificationByIdStart,
   ageVerificationByIdCompleted,
   ageVerificationByIdFailed,
   updatePaymentPendingDataAction,
   changeNewOrdersCountAction,
   updateSiteInfoAction,
   // updateLiveStreamBannerState,
} from './actions';
import { push, replace } from 'connected-react-router';
import Router from 'sites/routes/router';
import moment from 'moment';
import LocalStorage from 'sites/utils/LocalStorage';
import { getSiteUrl } from 'sites/utils/Helpers';
import { isLocalhost } from 'sites/utils/Helpers';
import { setIsConnectedAction } from 'sites/state/modules/livestream/actions'
import { initLiveStreamSocket, initNotificationsSocket, initOrderRequestsSocket } from 'sites/utils/socket';
import { initLiveStreamOperation } from 'sites/state/modules/livestream/operations';
import { fetchInitCompleted, setOpenEndedModal, setWatchingUsers, fetchInitRequest as fetchInitLivestreamRequest, fetchInitCompleted as fetchInitLivestreamCompleted, updateInitedData } from 'sites/state/modules/livestream/actions';
import { isAllowedRouteIfNeedAgeVerification, onInitIntercomeData } from 'sites/utils/utils';
import { setCookieValue, onTranslteLanguage, addDynamicCode } from 'common/utils/utils';
import { setIsInitedState as setIsInitedVideoState } from 'sites/state/modules/videos/actions';
import { setIsInitedState as setIsInitedPhotosetState } from 'sites/state/modules/photosets/actions';
import { updateOrderRequestsData } from '../store/actions';
import { showLoaderAfterTranslationAction } from '../chat/actions';
import { checkAndStorePoll } from '../polls/actions';


export const checkIsCommingSoon = (data) => {
   return async (dispatch) => {
      if((!window.isBlocked && !data?.user && !Boolean(LocalStorage.getItem('login-as'))) || (!!data?.user && !data.user.fake && 'support@adultmembersites.com' !== data.user.email && 'support@mymember.site' !== data.user.email)) {
         if(!!data.site_info.is_site_in_temporary_mode) {
            const bypassSetTime = LocalStorage.getItem('bypassSetTime');
            const currenTime = moment().utc().unix();
            if(!bypassSetTime || (currenTime - bypassSetTime) > 10800) {
               dispatch(replace(Router.route('COMING_SOON').getCompiledPath()));
            }
         }
      }
   }
}
export const siteDetailsInitOperation = () => {
   return async (dispatch, getState) => {
      dispatch(siteDetailsInitStart());
      try {
         let referrer_url;
         if(!window.redirectPerformed) {
            window.redirectPerformed = true;
            const domainType = document.querySelector('meta[name=domainType]').content;
            const subdomain = document.querySelector('meta[name=subdomain]').content;
            referrer_url = window.location.pathname.replace('/', '')
            if(!isLocalhost() && 'site_name_prefix' === domainType) {
               referrer_url = referrer_url.replace(subdomain, '')
            }
            if('/' === referrer_url[0]) {
               referrer_url = referrer_url.substring(1);
            }
         }
         const { data } = await initSiteDetails(referrer_url);

         // Chech if age verification required
         // Depending on this we blur all NSFW content
         const isAgeVerificationRequired = data.is_adult_verified && data.is_adult_verified !== 'verified' && data.is_adult_verified !== 'unnecessary' && LocalStorage.getItem('IS_AGE_VERIFIED_BY_ID') !== 'true' && localStorage.getItem('IS_AGE_VERIFIED_BY_ID') !== 'true'
         if(isAgeVerificationRequired) {
            // const isOnAgeVerificationRoutes = Router.route('AGE_VERIFICATION').match(window.location.pathname) || Router.route('AGE_VERIFICATION_PROCESS').match(window.location.pathname)

            // if(!isOnAgeVerificationRoutes) {
            //    dispatch(setShowNSFWModalAction(true))
            // }

            if(!isAllowedRouteIfNeedAgeVerification(window.location.pathname)) {
               dispatch(push(Router.route('AGE_VERIFICATION').getCompiledPath()))
            }

         }

         const { site_info: { redirect_to, gtag, is_dark_bg, tracking_code } } = data;
         if(is_dark_bg){
            document.documentElement.classList.add('dark')
            document.documentElement.classList.add('mode-dark')
            window.site_dark_bg = true
         } else {
            document.documentElement.classList.remove('dark')
            document.documentElement.classList.remove('mode-dark')
            window.site_dark_bg = false
         }
         if(data.site_info?.is_louisiana_blocked){
            QueryParams.addQueryParam('louisiana-block', true);
            if(data.site_info?.ip){
               QueryParams.addQueryParam('ip', data.site_info?.ip);
            }
         } else if(QueryParams.getParam('louisiana-block')){
            QueryParams.removeQueryParam('louisiana-block');
            if(QueryParams.getParam('ip')){
               QueryParams.removeQueryParam('ip');
            }
         }
         // if(window.google)
         if(redirect_to) {
            const mainUrl = process.env.NODE_ENV === 'production' ? getSiteUrl() : window.location.host;
            if(redirect_to === '/'){
               window.location.href = `${ window.location.protocol }//${ mainUrl }`;
               return
            }
            window.location.href = `${ window.location.protocol }//${ mainUrl }/${ redirect_to }`;
            return;
         }
         if(gtag && !window.gtagInited) {
            window.dataLayer = window.dataLayer || [];
            window.gtag = function(){ window.dataLayer.push(arguments); }
            window.gtag('js', new Date());
            window.gtag('config', gtag);
         }
         dispatch(checkIsCommingSoon(data));
         if(data.user && data.user?.translte_language){
            setCookieValue('googtrans', `/en/${ data.user.translte_language }`, 1000);
            onTranslteLanguage(data.user.translte_language);
            window.google_translte_language = data.user?.translte_language;
         } else if(!isLocalhost()){
            const browserLanguage = navigator.language || navigator.userLanguage;
            if(browserLanguage){
               const browserLanguageArr = browserLanguage.split('-');
               const defaultBrowserLanguage = browserLanguageArr[0];
               if(defaultBrowserLanguage){
                  setCookieValue('googtrans', `/en/${ defaultBrowserLanguage }`, 1000);
                  onTranslteLanguage(defaultBrowserLanguage);
                  window.google_translte_language = defaultBrowserLanguage;
               }
            }
         }
         const siteLongName = data.site_info.site_long_name;
         const subdomain = data.site_info.subdomain;

         onInitIntercomeData(data);
         const intercomeAction = QueryParams.getParam('intercom-action');
         if(window.Intercom && intercomeAction){
            window.Intercom(intercomeAction);
         }
         if(!window.document.title){
            window.document.title = siteLongName;
         }
         const titleElement = window.document.querySelector('title');
         if(titleElement?.classList?.add){
            titleElement.classList.add('notranslate');
         }
         window.skinPrimaryColor = data.site_info.skin_primary_color;
         window.skinSecondaryColor = data.site_info.skin_secondary_color;
         const favicon = data.site_info.favicon;
         const faviconRel = document.querySelector("link[rel='shortcut icon']");
         if(faviconRel) {
            faviconRel.href = favicon;
         }
         const { router: { location: { pathname } } } = getState();

         const body_code = tracking_code?.body_code;
         const header_code = tracking_code?.header_code;
         if(header_code && !window.header_code_is_used){
            addDynamicCode(header_code, 'head');
            window.header_code_is_used = true;
         }
         if(body_code && !window.body_code_is_used){
            addDynamicCode(body_code, 'body');
            window.body_code_is_used = true;
         }

         if(!Router.route('LIVE_STREAM_STUDIO').match(pathname)){
            dispatch(fetchInitLivestreamRequest(true));
            const livestream  = await initLiveStream();
            dispatch(fetchInitLivestreamCompleted({ ...livestream.data, is_show_banner: true }));
         }
         if(!!data.user){
            dispatch(socketConnection(subdomain))
            initNotificationsSocket(subdomain)
         }

         if(data.site_info.poll) {
            dispatch(checkAndStorePoll(data.site_info.poll))
         }

         dispatch(siteDetailsInitCompleted(data));

      } catch (error) {
         if(403 !== error?.response?.status){
            return dispatch(siteDetailsInitFailed(error));
         }
      }
   };
};

export const getWalletBalanceOperation = () => {
   return async (dispatch) => {
      dispatch(updateWalletBalanceStart());
      try {
         const { data } = await getWalletBalance();
         dispatch(updateWalletBalanc(data));
      } catch (error) {
         dispatch(updateWalletBalanceFailed())
      }
   };
};

export const loginAsOperation = (data) => {
   return async dispatch => {
      try {
         if(data && data.id) {
            await updatePreviewMember({ pricing_id: data.id, type: data.type });
         } else {
            await updatePreviewMember({ type: data.type });
         }
         dispatch(setViewAsModal({ isOpen: false, ...data }))
         dispatch(siteDetailsInitOperation());
      } catch (error) {
         Auth.logout();
      }
   }
}
export const onUpdateLanguageOperation = (data) => {
   return async (dispatch, getState) => {
      dispatch(showLoaderAfterTranslationAction(true))
      try {
         const { common: { siteInfo } } = getState()
         await updateTranslteLanguage(data);
         const { data: chatSettings } =  await chatInit()
         dispatch(updateSiteInfoAction({
            ...siteInfo,
            chat_settings: chatSettings?.chat_settings,
         }));
         dispatch(updateAuthUser(data));
      } catch (error) {
      } finally {
         dispatch(showLoaderAfterTranslationAction(false))
      }
   }
}

// export function initLiveStreamOperation(callback) {
//    return async dispatch => {
//       // dispatch(fetchInitRequest(true));
//       try {
//          const { data }  = await initLiveStream();
//          dispatch(updateLiveStreamBannerState(data));
//          if(callback) {
//             callback()
//          }
//       } catch (error) {
//          // dispatch(fetchInitRequest(false));
//       }
//    }
// }

export const socketConnection = subdomain => {
   return async (dispatch, getState) => {
      // const state = getState();
      // const { siteInfo } = state.common;

      const socket = initLiveStreamSocket(subdomain);
      window.memberOrderRequestsSocket = initOrderRequestsSocket(subdomain)

      socket.removeAllListeners('connect');
      socket.removeAllListeners('connect_error');
      socket.removeAllListeners('disconnect');
      socket.removeAllListeners('ready');
      socket.removeAllListeners('livestream:end');
      socket.removeAllListeners('livestream:watching-users');
      socket.removeAllListeners('livestream:likes-update');
      window.memberOrderRequestsSocket.removeAllListeners('order_request_admin:completed')

      socket.on('connect', (r) => {
         socket.emit('online')
      });
      socket.on('connect_error', (r) => {
         dispatch(setIsConnectedAction(false))
      });
      socket.on('disconnect', (res) => {
         dispatch(setIsConnectedAction(false))
      });
      socket.on('ready', () => {
         dispatch(setIsConnectedAction(true))
         const { livestream: { initData }, router: { location: { pathname } } } = getState();
         if('unlocked' === initData.stream_access_type && initData.is_live_stream_session_active && !initData.is_stream_offline && !!Router.route('LIVE_STREAM_STUDIO').match(pathname)) {
            socket.emit('watching', { isWatching: true });
            console.log('livestream ready emited')
         }
      });
      socket.on('livestream:update', (payload) => {
         if(window.streamIsEnd){
            window.streamIsEnd = false;
         }
         // console.log(payload, ' LIVESTREAM -> livestream update data')
         if(payload.force_update) {
            // console.log('LIVESTREAM -> Api call')
            dispatch(initLiveStreamOperation(false, true));
         } else {
            const { data, initiator } = payload;
            let newData = {}
            if(data) {
               const {
                  tip_actions,
                  details,
                  tip_goal,
               } = data
               newData = {
                  tip_actions_data: tip_actions.tip_action_is_on ? tip_actions?.tip_actions_data : [],
                  tip_goal: tip_goal.tip_goal_is_on ? tip_goal : {},
                  ...details,
               }
            }
            dispatch(fetchInitCompleted(newData, initiator))
            // console.log('LIVESTREAM -> Update redux data')
         }
      });

      socket.on('livestream:end', async (data) => {
         const { livestream: { initData } } = getState();
         if('locked' === initData.stream_access_type  || 'not_available' === initData.stream_access_type) {
            dispatch(setOpenEndedModal(true))
         } else {
            window.streamIsEnd = true;

         }
      });
      socket.on('livestream:watching-users', async (data) => {
         dispatch(setWatchingUsers(data))
      });
      socket.on('livestream:likes-update', (data) => {
         dispatch(updateInitedData(data))
      });

      window.memberOrderRequestsSocket.on('order_request_admin:completed', (data) => {
         if(data?.product_status_name === 'completed') return

         dispatch(changeNewOrdersCountAction(data?.new_statuses_count))

         if(data?.product_status_name === 'waiting'){
            let modifiedData = {
               ...data,
               product: {
                  ...data,
                  image_full_src: JSON.stringify(data?.image_paths),
               },
            }
            dispatch(updatePaymentPendingDataAction(modifiedData))
         }

         dispatch(updateOrderRequestsData(data))
      })
   };
};
export const resetGlobalStateOperation = (path) => {
   return async (dispatch) => {
      if(path && path.includes('/videos')){
         dispatch(setIsInitedVideoState(false));
      }
      if(path && path.includes('/photosets')){
         dispatch(setIsInitedPhotosetState(false));
      }
   };
};

export const ageVerificationByIdOperation = (data, callback) => {
   return async dispatch => {
      try {
         dispatch(ageVerificationByIdStart())
         const { data: response } = await verifyAgeById(data)
         console.log('ageVerificationByIdOperation response', response);
         dispatch(ageVerificationByIdCompleted(response.status))
         if(callback) {
            callback(response)
         }
      } catch (error) {
         console.log('ageVerificationByIdOperation error', error);
         dispatch(ageVerificationByIdFailed('failed'))
      }
   }
}

export const getRelatedContentsOperstion = (id, type, params = [], callback) => {
   return async dispatch => {
      try {
         const { data } = await getRelatedContents(id, type, params)
         if(callback) {
            callback('success', data)
         }
      } catch (error) {
         console.log(error);
         if(error && error?.code === "ERR_CANCELED") return
         if(callback) {
            callback('fail')
         }
      }
   }
}
