import React, { Component } from "react";
import PropTypes from "prop-types";
import { Route } from 'react-router';
import { connect } from "react-redux";
import { siteDetailsInitOperation, checkIsCommingSoon } from 'sites/state/modules/common/operations';
import {
   isSiteInitedSelector,
   authUserSelector,
   siteInfoSelector,
   isLouisianaBlockedSelector,
   isFetchingLogoutSelector,
} from 'sites/state/modules/common/selectors';
import Layout from 'common/layout';
import NavigationContainer from 'sites/containers/modules/navigation';
import StateBlock from 'sites/views/block/state';
import { wrongDownloadModalSelector } from 'sites/state/modules/media-resources/selectors';
import { setWrongDownloadButtonModal } from 'sites/state/modules/media-resources/actions';
import WrongDownloadModal from 'sites/views/media-modules/wrong-download-modal';
import PageLoader from "common/components/modules/page-loading";

class Mixed extends Component {
   static propTypes = {
      authUser: PropTypes.object,
      isSiteInited: PropTypes.bool.isRequired,
      component: PropTypes.any,
      init: PropTypes.func.isRequired,
      siteInfo: PropTypes.object.isRequired,
      isLouisianaBlocked: PropTypes.bool,
      closeWrongModal: PropTypes.func,
      wrongDownloadModal: PropTypes.object,
      isFetchingLogout: PropTypes.bool,
      checkIsCommingSoon: PropTypes.func,
   };

   componentDidMount() {
      const {
         init,
         isSiteInited,
         authUser,
         siteInfo,
         checkIsCommingSoon,
      } = this.props;
      if(!isSiteInited){
         init();
      } else {
         checkIsCommingSoon({ user: authUser, site_info: siteInfo })
      }
   }


   render() {
      const {
         component: ChildComponent,
         isSiteInited,
         siteInfo,
         isLouisianaBlocked,
         wrongDownloadModal,
         closeWrongModal,
         isFetchingLogout,
         ...rest
      } = this.props;

      if(isLouisianaBlocked){
         return (
            <StateBlock
               state='Louisiana'
               logo={ siteInfo.site_logo }
            />
         )
      }
      if(!isSiteInited || isFetchingLogout){
         return (
            <Layout>
               <div
                  className='w-full h-screen'
               >
                  <Layout.Content>

                     <PageLoader withLoader loaderColor='primary' />
                  </Layout.Content>
               </div>
            </Layout>
         )
      }
      if(isSiteInited) {
         return (
            <Layout>
               <div
                  style={ {
                     zIndex: 2,
                  } }
               >
                  <Layout.Menu>
                     <NavigationContainer />
                  </Layout.Menu>
               </div>
               <div
                  className='w-full'
                  style={ {
                     zIndex: 1,
                  } }
               >
                  <Layout.Header>
                     <NavigationContainer />
                  </Layout.Header>
                  <Layout.Content>
                     <Route
                        { ...rest }
                        render={ (matchProps) => {
                           return <ChildComponent { ...matchProps } />;
                        } }
                     />
                  </Layout.Content>
               </div>

               {
                  wrongDownloadModal.isOpen && (
                     <WrongDownloadModal
                        header={ wrongDownloadModal.header }
                        body={ wrongDownloadModal.body }
                        closeModal={ closeWrongModal }
                        isLimited={ wrongDownloadModal.is_limited }
                     />
                  )
               }
            </Layout>
         );
      }
      return null
   }
}

const mapStateToProps = (state) => {
   return {
      authUser: authUserSelector(state),
      isSiteInited: isSiteInitedSelector(state),
      siteInfo: siteInfoSelector(state),
      isLouisianaBlocked: isLouisianaBlockedSelector(state),
      wrongDownloadModal: wrongDownloadModalSelector(state),
      isFetchingLogout: isFetchingLogoutSelector(state),
   };
}

const mapDispatchToProps = (dispatch) => ({
   init: () => {
      dispatch(siteDetailsInitOperation())
   },
   checkIsCommingSoon: (data) => {
      dispatch(checkIsCommingSoon(data))
   },
   closeWrongModal: (data) => dispatch(setWrongDownloadButtonModal(data)),

});

export default connect(mapStateToProps, mapDispatchToProps)(Mixed);
